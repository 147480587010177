import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient'; // Import Supabase client
import { useUser } from "../context/userContext";
import { IoCheckmarkCircleSharp, IoCheckmarkCircle, IoClose } from 'react-icons/io5';
import { CiNoWaitingSign } from 'react-icons/ci';

const YouTubeTasks = () => {
    const [showVerifyButtons, setShowVerifyButtons] = useState({});
    const [countdowns, setCountdowns] = useState({});
    const [buttonText, setButtonText] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [claiming, setClaiming] = useState({});
    const [submittedYt, setSubmittedYt] = useState({});
    const { id: userId, youtubeTasks, setTaskPoints, userYoutubeTasks, setUserYoutubeTasks, setBalance } = useUser();
    const [claimedBonus, setClaimedBonus] = useState(0);
    const [congrats, setCongrats] = useState(false);
    const [openTask, setOpenTask] = useState(null);
    const [active, setActive] = useState(false);

    useEffect(() => {
        const handleBackButtonClick = () => {
            setOpenTask(false);
        };

        if (openTask) {
            window.Telegram.WebApp.BackButton.show();
            window.Telegram.WebApp.BackButton.onClick(handleBackButtonClick);
        } else {
            window.Telegram.WebApp.BackButton.hide();
            window.Telegram.WebApp.BackButton.offClick(handleBackButtonClick);
        }

        return () => {
            window.Telegram.WebApp.BackButton.offClick(handleBackButtonClick);
        };
    }, [openTask]);

    const performTask = (taskId) => {
        const task = youtubeTasks.find(task => task.id === taskId);
        if (task) {
            window.open(task.link, '_blank');
            setTimeout(() => {
                setShowVerifyButtons(prevState => ({ ...prevState, [taskId]: true }));
            }, 120000);
            setTimeout(() => {
                setActive(true);
            }, 2000);
        }
    };

    const startCountdown = (taskId) => {
        setCountdowns(prevState => ({ ...prevState, [taskId]: 5 }));
        setButtonText(prevState => ({ ...prevState, [taskId]: 'Verifying' }));

        const countdownInterval = setInterval(() => {
            setCountdowns(prevCountdowns => {
                const newCountdown = prevCountdowns[taskId] - 1;
                if (newCountdown <= 0) {
                    clearInterval(countdownInterval);
                    setCountdowns(prevState => ({ ...prevState, [taskId]: null }));
                    setButtonText(prevState => ({ ...prevState, [taskId]: 'Verifying' }));

                    const saveTaskToUser = async () => {
                        try {
                            const updatedUserYoutubeTasks = [...userYoutubeTasks, { taskId: taskId, completed: false }];
                            const { error } = await supabase
                                .from('telegramUsers')
                                .update({
                                    youtubeTasks: updatedUserYoutubeTasks
                                })
                                .eq('userId', userId);

                            if (error) throw error;
                            setUserYoutubeTasks(updatedUserYoutubeTasks);
                            console.log(`Task ${taskId} added to user's youtubeTasks collection`);
                        } catch (error) {
                            console.error("Error adding task to user's document: ", error);
                        }
                    };

                    saveTaskToUser();

                    setSubmittedYt(prevState => ({ ...prevState, [taskId]: true }));
                    localStorage.setItem(`submittedYt_${taskId}`, true);

                    return { ...prevCountdowns, [taskId]: null };
                }
                return { ...prevCountdowns, [taskId]: newCountdown };
            });
        }, 1000);
    };

    const claimTask = async (taskId) => {
        setClaiming(prevState => ({ ...prevState, [taskId]: true }));
        try {
            const task = youtubeTasks.find(task => task.id === taskId);
            const updatedUserYoutubeTasks = userYoutubeTasks.map(task =>
                task.taskId === taskId ? { ...task, completed: true } : task
            );
    
            // Fetch the current user data to get balance and taskPoints
            const { data: user, error: fetchError } = await supabase
                .from('telegramUsers')
                .select('balance, taskPoints')
                .eq('userId', userId)
                .single();
    
            if (fetchError) throw fetchError;
    
            // Calculate new balance and taskPoints values
            const newBalance = (user.balance || 0) + task.bonus;
            const newTaskPoints = (user.taskPoints || 0) + task.bonus;
    
            // Update user data in Supabase with new balance and taskPoints
            const { error } = await supabase
                .from('telegramUsers')
                .update({
                    youtubeTasks: updatedUserYoutubeTasks,
                    balance: newBalance,
                    taskPoints: newTaskPoints
                })
                .eq('userId', userId);
    
            if (error) throw error;
    
            // Update local state
            setBalance(newBalance);
            setTaskPoints(newTaskPoints);
            setUserYoutubeTasks(updatedUserYoutubeTasks);
    
            // Display modal message for success
            setModalMessage(
                <div className="w-full flex justify-center flex-col items-center space-y-3">
                    <div className="w-full items-center justify-center flex flex-col space-y-2">
                        <IoCheckmarkCircleSharp size={32} className="text-btn4" />
                        <p className="font-medium text-center">Let's go!!</p>
                    </div>
                    <h3 className="font-medium text-[20px] text-[#ffffff] pt-2 pb-2">
                        <span className="text-btn4">+{formatNumber(task.bonus)}</span> $GAMA CLAIMED
                    </h3>
                    <p className="pb-6 text-[15px] w-full text-center">
                        Keep performing new tasks! something huge is coming! Perform more and earn more $GAMA now! 
                    </p>
                </div>
            );
            
            setModalOpen(true);
            setClaimedBonus(task.bonus);
            setCongrats(true);
    
            // Hide congrats modal after a timeout
            setTimeout(() => {
                setCongrats(false);
            }, 4000);
    
        } catch (error) {
            console.error("Error updating task status to completed: ", error);
        } finally {
            setClaiming(prevState => ({ ...prevState, [taskId]: false }));
        }
    };
    
    const closeModal = () => {
        setModalOpen(false);
        setOpenTask(false);
    };

    const closeModal2 = () => {
        setModalOpen(false);
        setActive(false);
    };

    useEffect(() => {
        const submittedStatesYt = youtubeTasks.reduce((acc, task) => {
            const submittedStateYt = localStorage.getItem(`submittedYt_${task.id}`) === 'true';
            acc[task.id] = submittedStateYt;
            return acc;
        }, {});
        setSubmittedYt(submittedStatesYt);
    }, []);

    const formatNumber = (num) => {
        if (num < 100000) {
            return new Intl.NumberFormat().format(num).replace(/,/g, " ");
        } else if (num < 1000000) {
            return new Intl.NumberFormat().format(num).replace(/,/g, " ");
        } else {
            return (num / 1000000).toFixed(3).replace(".", ".") + " M";
        }
    };

    return (
        <>
            {youtubeTasks
                .sort((a, b) => a.id - b.id)
                .map(task => {
                    const userTask = userYoutubeTasks.find(t => t.taskId === task.id);
                    const isTaskCompleted = userTask ? userTask.completed : false;
                    return (
                        <div key={task.id} onClick={() => setOpenTask(task)} className="w-full rounded-[16px] py-3 flex items-center justify-between space-x-1">
                            <div className='w-fit pr-2'>
                                <div className='flex items-center justify-center bg-[#1f2023] h-[45px] w-[45px] rounded-full p-1'>
                                    <img alt="engy" src={task.icon} className='w-[20px]' />
                                </div>
                            </div>
                            <div className="flex h-full flex-1 flex-col justify-center relative">
                                <div className='flex w-full flex-col justify-between h-full space-y-1'>
                                    <h1 className="text-[15px] text-nowrap line-clamp-1 font-medium">
                                        {task.title}
                                    </h1>
                                    <span className='flex text-secondary items-center w-fit text-[15px]'>
                                        <span className=''>+{formatNumber(task.bonus)} $GAMA</span>
                                    </span>
                                </div>
                            </div>
                            <div className='w-fit flex items-center justify-end flex-wrap text-[14px] relative'>
                                {isTaskCompleted ? (
                                    <IoCheckmarkCircleSharp size={28} className="text-accent" />
                                ) : (
                                    <button
                                        onClick={() => setOpenTask(task)}
                                        className="bg-[#1f2023] hover:bg-[#36373c] text-[#fff] w-fit py-[10px] rounded-[30px] px-5 font-semibold ease-in duration-200"
                                    >
                                        Start
                                    </button>
                                )}
                            </div>
                        </div>
                    );
                })}
            {/* Add the modal here */}
        </>
    );
};

export default YouTubeTasks;
