import React, { useState } from 'react';
import { useUser } from "../context/userContext"; 
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import { CiNoWaitingSign } from "react-icons/ci";
import { supabase } from '../supabaseClient';

const ManualTasks = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const { id: userId, manualTasks, userManualTasks = [], setTaskPoints, setUserManualTasks, setBalance } = useUser();
  const [congrats, setCongrats] = useState(false);

  const performTask = async (taskId) => {
    const task = manualTasks.find(task => task.id === taskId);
    
    // Check if the task is already completed
    const isTaskCompleted = Array.isArray(userManualTasks) && userManualTasks.some(t => t.taskId === taskId && t.completed);
  
    if (task && !isTaskCompleted) {
      window.open(task.link, '_blank');

      // Immediately give the reward
      try {
        const { data: user, error: fetchError } = await supabase
          .from('telegramUsers')
          .select('balance, taskPoints')
          .eq('userId', userId)
          .single();

        if (fetchError) throw fetchError;

        const newBalance = (user.balance || 0) + task.bonus;
        const newTaskPoints = (user.taskPoints || 0) + task.bonus;

        // Mark the task as completed
        const updatedTasks = [...userManualTasks, { taskId, completed: true }];

        // Update the user's data in Supabase
        const { error: updateError } = await supabase
          .from('telegramUsers')
          .update({
            balance: newBalance,
            taskPoints: newTaskPoints,
            manualTasks: updatedTasks // Update the tasks in the database
          })
          .eq('userId', userId);

        if (updateError) throw updateError;

        // Update local states
        setBalance(newBalance);
        setTaskPoints(newTaskPoints);
        setUserManualTasks(updatedTasks);
        
        setCongrats(true);
        setModalMessage(
          <div className="w-full flex justify-center flex-col items-center space-y-3">
            <div className="w-full items-center justify-center flex flex-col space-y-2">
              <IoCheckmarkCircleSharp size={32} className={`text-accent`}/>
              <p className='font-medium text-center'>Reward Claimed!</p>
            </div>
            <h3 className="font-medium text-[20px] text-[#ffffff] pt-2 pb-2">
              <span className={`text-accent`}>+{task.bonus}</span> $GAMA CLAIMED
            </h3>
            <p className="pb-6 text-[15px] w-full text-center">
              Keep performing new tasks! Something huge is coming! Perform more and earn more $GAMA now!
            </p>
          </div>
        );

        setModalOpen(true);
        setTimeout(() => {
          setCongrats(false);
        }, 4000);
  
      } catch (error) {
        console.error("Error updating task status to completed: ", error);
      }
    } else if (isTaskCompleted) {
      // Show a message if the task has already been completed
      setModalMessage(
        <div className="w-full flex justify-center flex-col items-center space-y-3">
          <div className="w-full items-center justify-center flex flex-col space-y-2">
            <CiNoWaitingSign size={32} className={`text-accent`}/>
            <p className='font-medium text-center'>You have already completed this task!</p>
          </div>
        </div>
      );
      setModalOpen(true);
    }
  };
  
  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      {manualTasks
        .sort((a, b) => a.id - b.id)
        .map(task => {
          const userTask = Array.isArray(userManualTasks) ? userManualTasks.find(t => t.taskId === task.id) : null;
          const isTaskCompleted = userTask ? userTask.completed : false;

          return (
            <div key={task.id} className="w-full rounded-[16px] py-3 flex items-center justify-between space-x-1">
              <div className='w-fit pr-2'>
                <div className='flex items-center justify-center bg-[#1f2023] h-[45px] w-[45px] rounded-full p-1'>
                  <img alt="task icon" src={task.icon} className='w-[20px]' />
                </div>
              </div>
              <div className={`flex flex-1 h-full flex-col justify-center relative`}>
                <div className={`flex flex-col justify-between h-full space-y-1`}>
                  <h1 className={`text-[15px] line-clamp-1 font-medium`}>{task.title}</h1>
                  <span className='flex text-secondary items-center w-fit text-[15px]'>
                    <span className=''>+{task.bonus} $GAMA</span>
                  </span>
                </div>
              </div>

              <div className='w-fit flex items-center space-x-1 justify-end flex-wrap text-[14px] relative'>
                {!isTaskCompleted && (
                  <button
                    onClick={() => performTask(task.id)}
                    className={`w-[78px] py-[10px] text-center font-semibold rounded-[30px] px-3 bg-[#1f2023] hover:bg-[#36373c] text-[#fff]`}
                  >
                    Start
                  </button>
                )}

                {isTaskCompleted && (
                  <span className=''>
                    <IoCheckmarkCircleSharp size={28} className={`text-accent`} />
                  </span>
                )}
              </div>
            </div>
          );
        })}

      {congrats && <img src='/congrats.gif' alt="congrats" className="w-[80%] absolute top-0 left-0 right-0 z-50 pointer-events-none" />}

      {modalOpen && (
        <div className="fixed inset-0 z-40 flex items-center justify-center bg-[#00000042] backdrop-blur-[6px] px-4">
          <div className="w-full bg-modal rounded-[16px] p-8">
            {modalMessage}
            <div className="w-full flex justify-center mt-6">
              <button onClick={closeModal} className="bg-btn4 py-[10px] px-6 rounded-[12px] text-[16px] font-medium">
                Continue tasks
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ManualTasks;
