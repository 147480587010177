import React, { useEffect, useState } from 'react';
import { PiCheckCircle } from "react-icons/pi";
import { useUser } from '../context/userContext';
import { supabase } from '../supabaseClient';

const NewUser = ({ setChecker }) => {
  const [currentCheck, setCurrentCheck] = useState(0);
  const [visibleChecks, setVisibleChecks] = useState(0);
  const [completedChecks, setCompletedChecks] = useState([]);
  const {
    id,
    premium,
    premiumReward,
    setCheckinRewards,
    setShowClaimModal,
    setLastCheckIn,
    setCheckInDays,
    setPremiumReward,
    setBalance,
  } = useUser();

  // eslint-disable-next-line
  const [showRewards, setShowRewards] = useState(false);
  const [premiumRewards, setPremiumRewards] = useState(false);
  const [finishRewards, setFinishRewards] = useState(false);
  const [dailyCheckin, setDailyCheckin] = useState(false);
  const [awardedPoint, setAwardedPoint] = useState(0);
  const premiumPoints = 5000;
  const checkinPoint = 500;

  const awardPointsPrem = async () => {
    const firstDigit = parseInt(id.toString()[0]);
    const pointsToAward = firstDigit * 1000;
    const newBalance = pointsToAward + checkinPoint + premiumPoints;

    try {
      const now = new Date();
      const { error } = await supabase
        .from('telegramUsers')
        .update({
          balance: newBalance,
          yearsReward: pointsToAward,
          checkinRewards: checkinPoint,
          premiumReward: premiumReward + premiumPoints,
          checkInDays: [1],
          lastCheckIn: now,
        })
        .eq('userId', id);

      if (error) throw error;

      setBalance(newBalance);
      setAwardedPoint(pointsToAward);
      setCheckinRewards(checkinPoint);
      setPremiumReward(premiumPoints);
      setLastCheckIn(now);
      setCheckInDays([1]);
      setShowClaimModal(false);
    } catch (error) {
      console.error('Error updating user points:', error);
    }
  };

  const awardPointsNotPrem = async () => {
    const firstDigit = parseInt(id.toString()[0]);
    const pointsToAward = firstDigit * 1000;
    const newBalance = pointsToAward + checkinPoint;

    try {
      const now = new Date();
      const { error } = await supabase
        .from('telegramUsers')
        .update({
          balance: newBalance,
          yearsReward: pointsToAward,
          checkinRewards: checkinPoint,
          checkInDays: [1],
          lastCheckIn: now,
        })
        .eq('userId', id);

      if (error) throw error;

      setBalance(newBalance);
      setAwardedPoint(pointsToAward);
      setCheckinRewards(checkinPoint);
      setLastCheckIn(now);
      setCheckInDays([1]);
      setShowClaimModal(false);
    } catch (error) {
      console.error('Error updating user points:', error);
    }
  };

  useEffect(() => {
    if (id) {
      if (premium) {
        awardPointsPrem();
      } else {
        awardPointsNotPrem();
      }
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (visibleChecks < 4) {
      const timer = setTimeout(() => {
        setVisibleChecks(visibleChecks + 1);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [visibleChecks]);

  useEffect(() => {
    if (currentCheck < 4 && currentCheck < visibleChecks) {
      const timer = setTimeout(() => {
        setCurrentCheck(currentCheck + 1);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [currentCheck, visibleChecks]);

  const handleTransitionEnd = (index) => {
    setCompletedChecks((prev) => [...prev, index]);
  };

  const allChecksCompleted = completedChecks.length === 4;

  const openCollect = () => {
    setPremiumRewards(true);
    setShowRewards(false);
  };

  const openPremium = () => {
    setFinishRewards(true);
    setPremiumRewards(false);
  };

  const openFinish = () => {
    setDailyCheckin(true);
    setFinishRewards(false);
  };

  const openDone = () => {
    setChecker(false);
    setDailyCheckin(false);
  };

  const formatNumber = (number) => {
    if (number === undefined || number === null || isNaN(number)) {
      return '';
    }

    if (number >= 1000000) {
      return (number / 1000000).toFixed() + 'M';
    } else if (number >= 100000) {
      return (number / 1000).toFixed(0) + 'K';
    } else {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
  };

  return (
    <>
      <div className='w-full h-full relative pb-24 flex items-center justify-center flex-col space-y-20'>
        <div className='font-bold checking w-full absolute top-8 text-center text-[#fff]'>
          Checking your account
        </div>

        <div className='flex flex-col w-full justify-start items-center px-1 space-y-16'>
          <div className="flex flex-col items-start !mt-[-100px] space-y-8 w-full">
            {['Account Age Verified', 'Activity Level Analyzed', 'Telegram Premium Checked', 'OG Status Confirmed'].map((check, index) => (
              <div key={index} className={`flex flex-col items-center gap-2 w-full transition-opacity duration-500 ${index < visibleChecks ? 'opacity-100' : 'opacity-0'}`}>
                <div className='w-full flex justify-between items-center'>
                  <div className='text-[16px] font-semibold'>{check}</div>
                  <PiCheckCircle size={20} className={`${completedChecks.includes(index) ? 'text-accent' : 'text-white'}`} />
                </div>
                <div className="w-full h-2 bg-gray-300 rounded-[2px] relative overflow-hidden">
                  <div
                    className={`h-full bg-btn ${currentCheck > index ? 'w-full' : 'w-0'} transition-all duration-[2000ms]`}
                    onTransitionEnd={() => handleTransitionEnd(index)}
                  ></div>
                </div>
              </div>
            ))}
          </div>

          {allChecksCompleted && (
            <>
              {premium ? (
                <button onClick={openCollect} className={`w-full py-3 rounded-[8px] absolute bottom-10 font-semibold px-3 items-center justify-center text-center bg-btn`}>
                  Continue
                </button>
              ) : (
                <button onClick={openPremium} className={`w-full py-3 rounded-[8px] absolute bottom-10 font-semibold px-3 items-center justify-center text-center bg-btn`}>
                  Continue
                </button>
              )}
            </>
          )}
        </div>
      </div>

      {premium && premiumRewards && (
        <div className="fixed left-0 right-0 z-20 top-[-12px] bottom-0 flex bg-[#000] justify-center px-[16px] h-full">
          <div className="w-full pt-10 justify-center flex-col items-center space-y-4 relative">
            <div className='w-full flex justify-center space-x-2'>
              <div className={`w-[43%] h-[6px] rounded-[50px] ${premiumRewards ? 'bg-btn' : 'bg-btn4 opacity-[16%]'}`}></div>
              <div className={`w-[43%] h-[6px] rounded-[50px] ${finishRewards ? 'bg-btn' : 'bg-btn4 opacity-[16%]'}`}></div>
            </div>
            <div className='w-full absolute'>
              <img src='/congrats2.gif' alt='cong' className='w-full' />
            </div>
            <div className='w-full flex flex-col space-y-5 items-center'>
              <h2 className='font-bold text-[30px] text-center'>Telegram Premium</h2>
              <p className='text-[16px] font-semibold pb-6'>You are one of the best!</p>
              <img src='/prem.svg' alt='sdf' className='w-[280px] premiumsvg' />
              <p className='text-[20px] text-white font-bold !mt-0'>Premium user</p>
            </div>
          </div>
          <div className='w-full absolute bottom-7 flex px-4'>
            <div className='w-full flex flex-col items-center space-y-4 text-center'>
              <p className='max-w-[28em] textlow text-white font-semibold'>Status confirmed</p>
              <button onClick={openPremium} className={`w-full py-3 rounded-[8px] font-semibold px-3 items-center justify-center text-center bg-[#fff] text-[#000]`}>
                Continue
              </button>
            </div>
          </div>
        </div>
      )}

      {finishRewards && (
        <div className="fixed left-0 right-0 z-20 top-[-12px] bottom-0 flex bg-[#000] justify-center px-[16px] h-full">
          <div className="w-full pt-10 justify-center flex-col items-center space-y-4 relative">
            <div className='w-full flex justify-center space-x-2'>
              <div className={`${premium ? 'w-[43%]' : 'hidden'} h-[6px] rounded-[50px] ${finishRewards ? 'bg-btn' : 'bg-btn4 opacity-[16%]'}`}></div>
              <div className={`${premium ? 'w-[43%]' : 'w-[86%]'} h-[6px] rounded-[50px] ${finishRewards ? 'bg-btn' : 'bg-btn4 opacity-[16%]'}`}></div>
            </div>
            <div className='w-full flex flex-col space-y-5 items-center'>
              <h2 className='font-bold text-[30px] text-center'>{premium ? 'You are the best' : 'Welcome to GAMA'}</h2>
              <p className='text-[16px] font-semibold pb-14'>Here is your $GAMA rewards</p>
              <img src='/stars.svg' alt='sdf' className='w-[80px] premiumsvg' />
              <p className='text-[24px] text-white font-bold !mt-3'>
                +{premium ? formatNumber(awardedPoint + premiumPoints) : formatNumber(awardedPoint)}
              </p>
              <p className='text-[16px] !mt-0'>$GAMA</p>
            </div>
          </div>
          <div className='w-full absolute bottom-7 flex px-4'>
            <div className='w-full flex flex-col items-center space-y-4 text-center'>
              <p className='max-w-[28em] textlow text-white font-semibold'>If you missed Dogs, don't miss {process.env.REACT_APP_PROJECT_NAME_SMALL}!</p>
              <button onClick={openFinish} className={`w-full py-3 rounded-[8px] font-semibold px-3 items-center justify-center text-center bg-[#fff] text-[#000]`}>
                Continue
              </button>
            </div>
          </div>
        </div>
      )}

      {dailyCheckin && (
        <div className="fixed left-0 right-0 z-20 top-[-12px] bottom-0 flex bg-[#000] justify-center px-[16px] h-full">
          <div className="w-full pt-10 justify-center flex-col items-center space-y-4 relative">
            <div className='w-full flex flex-col space-y-5 items-center pt-16'>
              <h2 className='font-bold text-[42px]'>Day 1</h2>
              <p className='text-[20px] text-white font-bold !mt-1'>Daily Checkin</p>
              <p className='text-[16px] text-white !mt-1'>+{formatNumber(checkinPoint)} $GAMA</p>
              <img src='/checkin.svg' alt='sdf' className='w-[150px]' />
            </div>
          </div>
          <div className='w-full absolute bottom-7 flex px-4'>
            <div className='w-full flex flex-col items-center space-y-4 text-center'>
              <p className='max-w-[28em] textlow text-white font-medium px-1'>Visit daily to maintain your streak! If you miss a day, you lose your progress and start afresh 😎</p>
              <button onClick={openDone} className={`w-full py-3 rounded-[8px] font-semibold px-3 items-center justify-center text-center bg-btn`}>
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NewUser;
