import React, { useState, useEffect } from 'react';
import { useUser } from "../context/userContext"; 
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { CiNoWaitingSign } from "react-icons/ci";
import { supabase } from '../supabaseClient';

const Partners = () => {
  const [showVerifyButtons, setShowVerifyButtons] = useState({});
  const [countdowns, setCountdowns] = useState({});
  const [buttonText, setButtonText] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [claiming, setClaiming] = useState({});
  const [submittedTwo, setSubmittedTwo] = useState({});
  const { id: userId, advertTasks, setTaskPoints, userAdvertTasks, setUserAdvertTasks, setBalance } = useUser();
  const [claimedBonus, setClaimedBonus] = useState(0); 
  const [congrats, setCongrats] = useState(false);

  // Open a task link and enable verify button after a short delay
  const performTask = (taskId) => {
    const task = advertTasks.find(task => task.id === taskId);
    if (task) {
      window.open(task.link, '_blank');
      setTimeout(() => setShowVerifyButtons(prev => ({ ...prev, [taskId]: true })), 2000);
    }
  };

  // Start countdown and update UI messages for task verification
  const startCountdown = (taskId) => {
    setCountdowns(prev => ({ ...prev, [taskId]: 5 }));
    setButtonText(prev => ({ ...prev, [taskId]: 'Verifying...' }));

    const countdownInterval = setInterval(() => {
      setCountdowns(prev => {
        const newCountdown = prev[taskId] - 1;
        if (newCountdown <= 0) {
          clearInterval(countdownInterval);
          handleCountdownComplete(taskId);
        }
        return { ...prev, [taskId]: newCountdown };
      });
    }, 1000);
  };

  // Handle countdown completion logic, display modal, and update task in Supabase
  const handleCountdownComplete = async (taskId) => {
    setCountdowns(prev => ({ ...prev, [taskId]: null }));
    setButtonText(prev => ({ ...prev, [taskId]: 'Verifying' }));
    showModal('Wait 30 minutes for moderation check to claim bonus!!');
    await saveTaskProgress(taskId);
    setSubmittedTwo(prev => ({ ...prev, [taskId]: true }));
    localStorage.setItem(`submittedTwo_${taskId}`, true);
  };

  // Save task progress to Supabase
  const saveTaskProgress = async (taskId) => {
    try {
      const { error } = await supabase
        .from('telegramUsers')
        .update({ advertTasks: [{ taskId, completed: false }] })
        .eq('userId', userId);
      if (error) throw error;
      console.log(`Task ${taskId} added to user's advertTasks collection`);
    } catch (error) {
      console.error("Error adding task to user's document: ", error);
    }
  };

  // Claim task reward, update state, and display congratulatory modal
  const claimTask = async (taskId) => {
    setClaiming(prev => ({ ...prev, [taskId]: true }));
    try {
      const task = advertTasks.find(task => task.id === taskId);
      const updatedAdvertTasks = userAdvertTasks.map(t =>
        t.taskId === taskId ? { ...t, completed: true } : t
      );
      
      const { error } = await supabase
        .from('telegramUsers')
        .update({
          advertTasks: updatedAdvertTasks,
          balance: (prevBalance) => prevBalance + task.bonus,
          taskPoints: (prevTaskPoints) => prevTaskPoints + task.bonus,
        })
        .eq('userId', userId);

      if (error) throw error;

      // Update local states
      setBalance(prevBalance => prevBalance + task.bonus);
      setTaskPoints(prevTaskPoints => prevTaskPoints + task.bonus);
      setUserAdvertTasks(updatedAdvertTasks);

      showModal(`+${formatNumber(task.bonus)} NGT CLAIMED`, true);
      setClaimedBonus(task.bonus);
      setCongrats(true);
      setTimeout(() => setCongrats(false), 4000);

    } catch (error) {
      console.error("Error updating task status to completed: ", error);
    }
    setClaiming(prev => ({ ...prev, [taskId]: false }));
  };

  // Display the modal with a message
  const showModal = (message, isClaim = false) => {
    setModalMessage(
      <div className="w-full flex justify-center flex-col items-center space-y-3">
        <div className="w-full items-center justify-center flex flex-col space-y-2">
          {isClaim ? <IoCheckmarkCircleSharp size={32} className="text-accent" /> : <CiNoWaitingSign size={32} className="text-accent" />}
          <p className='font-medium text-center'>{message}</p>
        </div>
      </div>
    );
    setModalOpen(true);
  };

  const closeModal = () => setModalOpen(false);

  // Restore task submission state on mount
  useEffect(() => {
    const savedStates = advertTasks.reduce((acc, task) => {
      const isSubmitted = localStorage.getItem(`submittedTwo_${task.id}`) === 'true';
      acc[task.id] = isSubmitted;
      return acc;
    }, {});
    setSubmittedTwo(savedStates);
  }, [advertTasks]);

  // Helper to format numbers
  const formatNumber = (num) => num < 1e6 ? new Intl.NumberFormat().format(num) : `${(num / 1e6).toFixed(3)} M`;

  return (
    <>
      {advertTasks.sort((a, b) => a.id - b.id).map(task => {
        const userTask = userAdvertTasks.find(t => t.taskId === task.id);
        const isTaskCompleted = userTask ? userTask.completed : false;
        const isTaskSaved = !!userTask;

        return (
          <div key={task.id} className="w-full rounded-[16px] py-3 flex items-center justify-between space-x-1">
            <div className='w-fit pr-2'>
              <div className='flex items-center justify-center bg-[#1f2023] h-[45px] w-[45px] rounded-full p-1'>
                <img alt="task icon" src={task.icon} className='w-[20px]' />
              </div>
            </div>
            <div className="flex flex-1 flex-col justify-center space-y-1">
              <h1 className="text-[15px] font-medium">{task.title}</h1>
              <span className="flex items-center text-secondary text-[15px]">
                +{formatNumber(task.bonus)} $GAMA
              </span>
            </div>
            <div className="w-fit flex items-center space-x-1 justify-end text-[14px]">
              {!isTaskSaved && !isTaskCompleted && (
                <>
                  <button
                    onClick={() => performTask(task.id)}
                    className="w-[78px] py-[10px] text-center font-semibold rounded-[30px] px-3 bg-[#1f2023] hover:bg-[#36373c] text-white"
                  >
                    Start
                  </button>
                  {countdowns[task.id] === undefined && (
                    <button
                      onClick={() => startCountdown(task.id)}
                      className="w-[54px] py-[10px] text-center font-semibold text-[12px] rounded-[12px] bg-btn4 text-black"
                      disabled={!showVerifyButtons[task.id] || submittedTwo[task.id]}
                    >
                      {submittedTwo[task.id] ? 'Checking' : buttonText[task.id] || 'Check'}
                    </button>
                  )}
                </>
              )}
              {isTaskSaved && !isTaskCompleted && (
                <button
                  onClick={() => claimTask(task.id)}
                  className="w-[78px] py-[10px] text-center font-semibold rounded-[30px] px-3 bg-btn text-black"
                  disabled={claiming[task.id]}
                >
                  {claiming[task.id] ? 'Claiming' : 'Claim'}
                </button>
              )}
              {countdowns[task.id] !== null && countdowns[task.id] !== undefined && (
                <span className="w-[42px] h-[34px] flex items-center justify-between rounded-[12px] px-[8px] font-medium bg-[#1f2023]">
                  <AiOutlineLoading3Quarters size={20} className="absolute animate-spin text-secondary" />
                  <span className="absolute text-[8px]">{countdowns[task.id]}s</span>
                </span>
              )}
              {isTaskCompleted && (
                <IoCheckmarkCircleSharp size={28} className="text-accent" />
              )}
            </div>
          </div>
        );
      })}

      {congrats && <img src='/congrats.gif' alt="congrats" className="w-[80%] absolute top-0 left-0 right-0 z-50 pointer-events-none" />}

      {modalOpen && (
        <div className="fixed inset-0 z-40 flex items-center justify-center bg-[#00000042] backdrop-blur-[6px] px-4">
          <div className="w-full bg-modal rounded-[16px] p-8 opacity-100 mt-0 ease-in duration-300">
            {modalMessage}
            <div className="w-full flex justify-center mt-6">
              <button onClick={closeModal} className="bg-btn4 py-[10px] px-6 rounded-[12px] text-[16px] font-medium">
                Continue tasks
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Partners;
