import React, { useState, useEffect, useContext, createContext } from "react";
import { supabase } from "../supabaseClient"; // Import your Supabase client

const AuthContext = createContext();

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState('');

  // Define fetchUserData outside of useEffect to make it accessible
  const fetchUserData = async (userId) => {
    const { data, error } = await supabase
      .from('users') // Make sure to use the correct table name
      .select('*') // Fetch the user data including role
      .eq('id', userId) // Assuming the user ID is the same as the Supabase user ID
      .single();

    if (error) {
      console.error("Error fetching user data:", error);
      setUser(null);
      setRole('');
    } else {
      setUser(data);
      setRole(data.role || ''); // Set the role if it exists
    }
  };

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      if (session) {
        setUser(session.user);
        fetchUserData(session.user.id); // Fetch user data including role
      } else {
        setUser(null);
        setRole(''); // Reset role when no user is authenticated
      }
      setLoading(false);
    });

    return () => {
      authListener.unsubscribe(); // Clean up the listener
    };
  }, []);

  // Define a login function
  const login = async (email, password) => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      console.error("Error logging in:", error);
      throw error; // Rethrow the error to be handled in the calling component
    }

    // If login is successful, fetch user data
    fetchUserData(data.user.id); // Make sure this matches your user ID column
  };

  // Define a logout function
  const logout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, loading, role, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
